import "../assets/css/Gallery.css";
import { memo, useState } from "react";

const Gallery = ({ images }) => {
	const [imgPopupId, setImgPopupId] = useState(0);
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	const showPopup = async () => {
		let popupSlider = document.querySelector(".popup-slider");
		let body = document.querySelector("body");
		popupSlider.classList.remove("hidden--popup");
		body.classList.add("hidden-overflow");
		popupSlider.classList.add("animation--silder");
		await delay(200);
		popupSlider.focus();
	};
	const hiddenPopup = async () => {
		let popupSlider = document.querySelector(".popup-slider");
		let body = document.querySelector("body");

		popupSlider.classList.add("animation--silder--out");
		popupSlider.classList.remove("animation--silder");
		setImgPopupId("brak");
		await delay(500);
		popupSlider.classList.add("hidden--popup");
		body.classList.remove("hidden-overflow");
		popupSlider.classList.remove("animation--silder--out");
	};
	return (
		<>
			<div className="gallery">
				{images.map((image, i) => (
					<div
						key={i}
						title="Powiększ zdjęcie.."
						className="img--box"
						onClick={() => {
							setImgPopupId(i);
							showPopup();
						}}>
						<img
							src={image.thumbnail}
							alt="Zdjecie z aktualnosci"
							loading="lazy"
							onLoad={(e) => {
								e.target.naturalWidth > 250 && e.target.parentElement.classList.add("small-image");
							}}
						/>
					</div>
				))}
			</div>
			<div
				className="popup-slider hidden--popup"
				onClick={(e) => {
					e.target.classList.contains("popup-slider") && hiddenPopup();
				}}
				onKeyDown={(e) => {
					e.code === "Escape" && hiddenPopup();
				}}
				tabIndex={-1}>
				<i
					className="fa-solid fa-xmark"
					onClick={() => hiddenPopup()}></i>
				{imgPopupId !== "brak" && (
					<img
						src={images[imgPopupId].original}
						alt="Zdjcie powiekszone"
					/>
				)}
			</div>
		</>
	);
};

export default memo(Gallery);
