import "../assets/css/CompetitionsContent.css";

import Form from "../data/formularz.docx";
import Rules from "../data/regulamin konkursu wiedzy o Rzeszowie.docx";
import Trips from "../data/harmonogram wycieczek.docx";
import Consent1 from "../data/KLAUZULA INFORMACYJNA DLA UCZNIA, Rodzica.docx";
import Consent2 from "../data/ZGODA NA WYKORZYSTANIE WIZERUNKU UCZNIA NIEPEŁNOLETNIEGO.docx";
import Consent3 from "../data/ZGODA NA WYKORZYSTANIE WIZERUNKU UCZNIA PEŁNOLETNIEGO.docx";
import PDF from "../data/afisz.pdf";

function CompetitionsContent() {
	return (
		<>
			<main className="competitions-content--box">
				<div>
					<h1 className="hidden">V edycja - Konkursu Wiedzy o Rzeszowie</h1>
					<hr />
					<p className="hidden">
						Zapraszamy młodzież Państwa szkoły do wzięcia udziału w Konkursu wiedzy o Rzeszowie „Rzeszów – to moje miasto, miasto, w którym
						mieszkam, uczę się i wypoczywam” organizowanego przez Stowarzyszenie „Rzeszów – to moje miasto”, Urząd Miasta Rzeszowa, Fundacja
						Książąt Lubomirskich. Celem konkursu jest:
					</p>
					<ul>
						<li className="hidden">
							<p>
								Odkrywanie bogactwa dziedzictwa kulturowego poprzez poznawanie przeszłości miasta; uświadamianie europejskiego wymiaru tego
								dziedzictwa;
							</p>
						</li>
						<li className="hidden">
							<p>
								Rozbudzanie i rozwijanie zainteresowania młodzieży szkół średnich swoim miastem, jego przeszłością, środowiskiem
								geograficznym, kulturą oraz życiem gospodarczym i społecznym;
							</p>
						</li>
						<li className="hidden">
							<p>Popularyzacja wiedzy na temat ludzi wpisanych w historię miasta, wskazywanie wzorców osobowych;</p>
						</li>
						<li className="hidden">
							<p>Kształtowanie poczucia więzi regionalnej poprzez upowszechnianie wiedzy o małej ojczyźnie.</p>
						</li>
					</ul>
					<hr />
					<div className="competition--invite">
						<p className="hidden">
							<span> Stowarzyszenie „Rzeszów – to moje miasto” </span>zwraca się z uprzejmą prośbą do PT Dyrektorów Szkół Średnich w
							Rzeszowie o zgłoszenie Szkoły do Konkursu wiedzy o Rzeszowie „Rzeszów - to moje miasto, miasto, w którym mieszkam, uczę się i
							wypoczywam” i wyznaczenie nauczyciela koordynatora konkursu. W tym roku odbędzie się już piąta edycja Konkursu wiedzy o
							Rzeszowie „Rzeszów - to moje miasto, miasto, w którym mieszkam, uczę się i wypoczywam”. W poprzednich edycjach konkurs cieszył
							się sporym zainteresowaniem młodzieży szkół średnich. Laureaci zostali nagrodzeni prestiżowymi nagrodami. Organizatorami
							konkursu są: <span> Stowarzyszenie „Rzeszów - to moje miasto”, Urząd Miasta Rzeszowa, Fundacja Książąt Lubomirskich.</span>
							Celem konkursu jest: a) odkrywanie bogactwa dziedzictwa kulturowego poprzez poznawanie przeszłości miasta; uświadamianie
							europejskiego wymiaru tego dziedzictwa, b) rozbudzanie i rozwijanie zainteresowania młodzieży szkół średnich swoim miastem,
							jego przeszłością, środowiskiem geograficznym, kulturą oraz życiem gospodarczym i społecznym, c) popularyzacja wiedzy na temat
							ludzi wpisanych w historię miasta, wskazywanie wzorców osobowych, d) kształtowanie poczucia więzi regionalnej poprzez
							upowszechnianie wiedzy o małej ojczyźnie. Zgłoszenia do konkursu może dokonać szkoła średnia (dyrekcja lub nauczyciel
							koordynator konkursu) poprzez przesłanie wypełnionej karty zgłoszeniowej (w załączniku). Zgłoszenie należy przesłać do{" "}
							<span> 10 listopada 2022 roku </span> na adres:{" "}
							<span> Stowarzyszenie „Rzeszów - to moje miasto”, ul. Adama Matuszczaka 7, 35-084 Rzeszów </span> lub e-mailem:{" "}
							<span> mmrz@rzmmpl </span>
							Konkurs przeznaczony jest dla uczniów szkół średnich. Celem większego zainteresowania konkursem młodzieży, w piątej edycji
							przewidujemy dwa etapy: etap szkolny i konkurs finałowy (test finałowy).
						</p>
						<hr />
						<div className="competition--attachments hidden">
							<a
								href={Form}
								download
								className="hidden">
								<p>
									<i className="fa-solid fa-file-arrow-down"></i>Formularz konkursu
								</p>
							</a>
							<a
								href={Rules}
								download
								className="hidden">
								<p>
									<i className="fa-solid fa-file-arrow-down"></i>Regulamin konkursu
								</p>
							</a>
						</div>
						<hr />
					</div>
					<div className="trip--info">
						<p className="hidden">
							Organizacja wycieczki po Rzeszowie: 1) Uczniowie muszą być pod opieką nauczyciela w trakcie <span>całej wycieczki</span>; 2)
							Nauczyciele <span>odpowiadają</span> za uczniów w trakcie wycieczki; 3) Miejsce zbiórki naprzeciw wejścia do Ratusza{" "}
							<span>godz. 12:10</span> (proszę o punktualne przyjście); 4) Szacunkowy <span> czas wycieczki to 2 godziny </span> zegarowe;
							Wycieczki odbędą się tylko przy założeniu sprzyjających warunków pogodowych.
						</p>
						<hr />
						<div className="trip--info--attachments hidden">
							<a
								href={Trips}
								download>
								<p>
									<i className="fa-solid fa-file-arrow-down"></i>Harmonogram wycieczek
								</p>
							</a>
						</div>
					</div>
					<hr />
					<div className="final--stage">
						<p className="hidden">
							Etap finałowy Konkursu wiedzy o Rzeszowie przeprowadzony zostanie się <span>18 kwietnia 2023 r., godz. 10:00</span> w auli
							Zespołu Szkół Technicznych im. E. Kwiatkowskiego w Rzeszowie przy ul. Adama Matuszczaka 7, 35-084 Rzeszów; Do etapu finałowego
							mogą przystąpić <span>tylko</span> uczniowie, których Koordynatorzy <span>dostarczyli zgody</span> na przetwarzanie danych
							osobowych i na wykorzystanie wizerunku do organizatorów konkursu.
						</p>
						<div className="final--stage--attachments">
							<h3 className="hidden">Zgody</h3>
							<hr />
							<a
								href={Consent1}
								download
								className="hidden">
								<p>
									<i className="fa-solid fa-file-arrow-down"></i>Klauzula Informacyjna
								</p>
							</a>
							<a
								href={Consent2}
								download
								className="hidden">
								<p>
									<i className="fa-solid fa-file-arrow-down"></i>Uczeń niepełnoletni
								</p>
							</a>
							<a
								href={Consent3}
								download
								className="hidden">
								<p>
									<i className="fa-solid fa-file-arrow-down"></i>Uczeń pełnoletni
								</p>
							</a>
						</div>
					</div>
					<hr />
					<div className="competition--gala">
						<p className="hidden">
							Stowarzyszenie „Rzeszów – to moje miasto”, Prezydent Miasta Rzeszowa, Fundacja Książąt Lubomirskich mają zaszczyt zaprosić na
							wręczenie nagród w Konkursie wiedzy o Rzeszowie „Rzeszów - to moje miasto, miasto, w którym mieszkam, uczę się i wypoczywam” w
							dniu <span>27 kwietnia 2023 r. o godz. 11:00</span> w Sali Sesyjnej Urzędu Miasta Rzeszowa.
						</p>
						<a
							href={PDF}
							target="_blank"
							rel="noreferrer"
							className="hidden">
							<p>
								<i className="fa-solid fa-magnifying-glass"></i>Zobacz Plakat
							</p>
						</a>
					</div>
					<hr />
				</div>
			</main>
		</>
	);
}
export default CompetitionsContent;
