import "react-lazy-load-image-component/src/effects/blur.css";
import "../assets/css/Start.css";

import { LazyLoadImage } from "react-lazy-load-image-component";

import BackgroundImage from "../assets/image/bg.jpg";
import PlaceholderBackgroundImage from "../assets/image/bg-placeholder.jpg"

function Start() {
	return (
		<>
			<main className="start--box ">
				<div className="box">
					<LazyLoadImage
						width={"100%"}
						src={BackgroundImage}
						placeholdersrc={PlaceholderBackgroundImage}
						effect="blur"
						alt="Rzeszow Rynek Zdjecie Wieczorem"
					/>
					<div>
						<h2>Stowarzyszenie</h2>
						<h1>Rzeszów to moje miasto</h1>
						<p>
							“Dziękuję Bogu, że jest Rzeszów, cudowne polskie miasto.“ <br /> ~Wojciech Kilar
						</p>
					</div>
				</div>
			</main>
		</>
	);
}
export default Start;
