import "../assets/css/Competitions.css";

import { Link } from "react-router-dom";

function Competitions() {
	return (
		<>
			<main className="competitions--box">
				<h1 className="fade-in-element">Konkurs Wiedzy o Rzeszowie</h1>
				<hr />
				<div className="competition--info">
					<div>
						<i className="fa-sharp fa-regular fa-calendar-days fade-in-element-left"></i>
						<h2 className="fade-in-element-right">V edycja - Konkursu Wiedzy o Rzeszowie</h2>
						<p className="fade-in-element-left">
							Zapraszamy młodzież Państwa szkoły do wzięcia udziału w Konkursu wiedzy o Rzeszowie „Rzeszów – to moje miasto, miasto, w
							którym mieszkam, uczę się i wypoczywam” organizowanego przez Stowarzyszenie „Rzeszów – to moje miasto”, Urząd Miasta Rzeszowa,
							Fundacja Książąt Lubomirskich.
						</p>

						<Link
							className="fade-in-element"
							to="/konkursy/0">
							Czytaj dalej...
						</Link>
						<hr />
					</div>
				</div>
			</main>
		</>
	);
}
export default Competitions;
