import "../assets/css/Partners.css";

import zstlogo from "../assets/image/zstlogo.png";

function Partners() {
	return (
		<>
			<main className="partners--box ">
				<h1 className="fade-in-element">Partnerzy</h1>
				<hr />
				<div className="partners">
					<li>
						<img
							src={zstlogo}
							alt="ZST logo"
							className="fade-in-element-right"
						/>
						<p className="fade-in-element-left">
							<a
								href="http://www.zstrzeszow.pl/"
								target="_blank"
								rel="noreferrer">
								<span>Zespół Szkół Technicznych </span>
							</a>
							im. Eugeniusza Kwiatkowskiego to szkoła położona w spokojnej dzielnicy miasta, na ulicy Matuszczaka, która jest boczną ulicy
							Podkarpackiej. Jesteśmy szkołą o silnej pozycji na edukacyjnym rynku miasta i regionu. Dewizą Zespołu Szkół Technicznych jest
							fakt, że zawsze staramy się pamiętać, że w szkole najważniejszy jest uczeń, duszą są nauczyciele, wsparciem rodzice, a dumą
							absolwenci.
						</p>
						<a
							className="link--hover-effect fade-in-element"
							href="http://www.zstrzeszow.pl/"
							target="_blank"
							rel="noreferrer">
							Zobacz stronę szkoły!
						</a>
					</li>
				</div>
				<hr />
			</main>
		</>
	);
}
export default Partners;
