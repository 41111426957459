import "../assets/css/Contact.css";

function Contact() {
	return (
		<>
			<main className="contact--box">
				<h1 className="hidden">Informacje</h1>
				<p className="hidden">Wszystke dane o stowarzyszeniu, adres, NIP, KRS, REGON, data rejestracji. </p>
				<div className="contact">
					<hr />
					<div className="informations">
						<div className="info fade-in-element-left">
							<i className="fa-solid fa-scale-balanced"></i>
							<p>
								<span>KRS:</span>0000667041
							</p>
						</div>
						<div className="info fade-in-element-right">
							<i className="fa-solid fa-magnifying-glass-dollar"></i>
							<p>
								<span>NIP:</span>8133740154
							</p>
						</div>
						<div className="info fade-in-element-left">
							<i className="fa-solid fa-school"></i>
							<p>
								<span>Adres siedziby:</span> ul. Adama Matuszczaka 7,
								<br /> 35-084 Rzeszów
							</p>
						</div>
						<div className="info fade-in-element-right">
							<i className="fa-solid fa-magnifying-glass-chart"></i>
							<p>
								<span>REGON:</span>366739322
							</p>
						</div>
						<div className="info fade-in-element-left">
							<i className="fa-solid fa-calendar-days"></i>
							<p>
								<span>Data rejestracji:</span>8 marca 2017r.
							</p>
						</div>
					</div>
					<div className="location_map hidden">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10255.191963689545!2d21.9784385!3d50.0151414!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfbb603346bd3%3A0x3aeac36b91c32a6!2sTeam%20Technical%20Schools.%20E.%20Kwiatkowski!5e0!3m2!1sen!2spl!4v1685203497199!5m2!1sen!2spl"
							title="Adress Szkoly ZST / ul. Matuszczaka 7 Rzeszów "
							allowFullScreen={true}
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
					<hr />
				</div>
			</main>
		</>
	);
}
export default Contact;
