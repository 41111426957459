import "../assets/css/News.css";

import { Link } from "react-router-dom";

function News() {
	return (
		<>
			<main className="news--box">
				<h1 className="fade-in-element">Aktualności Rzeszów</h1>
				<hr />
				<div className="news">
					<div>
						<i className="fa-regular fa-newspaper fade-in-element-left"></i>
						<h2 className="fade-in-element-right">V edycja - Konkursu wiedzy</h2>
						<p className="fade-in-element-left">
							W czwartek, 27 kwietnia, w Sali Sesyjnej Rzeszowskiego Ratusza wręczono nagrody w V edycji Konkursu wiedzy o Rzeszowie
							“Rzeszów - to moje miasto, miasto, w którym mieszkam, uczę się i wypoczywam”. W tegorocznej edycji zwyciężył Hubert Jaworski,
							uczeń IV LO w Rzeszowie....
						</p>
						<Link to="/aktualnosci/0">Czytaj dalej...</Link>
						<hr />
					</div>
				</div>
			</main>
		</>
	);
}
export default News;
