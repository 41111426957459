import "../assets/css/Association.css";

import Background from "../assets/image/bg.jpg";

function Association() {
	return (
		<>
			<main className="association--box">
				<div className="text_container">
					<img
						src={Background}
						alt="Rzeszow wieczorem widok na pomniek znak charakterystyczny Rzeszowa. Lokalizajca obok Galeri Rzeszów."
					/>
					<div className="box">
						<h1 className="fade-in-element-left">Historia Stowarzyszenia</h1>
						<p className="fade-in-element-right">
							Inicjatorem powstania Stowarzyszenia <b>„Rzeszów to moje miasto”</b> jest <b>prof. nadzw. dr hab. Adam Kulczycki.</b>
							Zamiarem członków założycieli Stowarzyszenia "Rzeszów to moje miasto" był zatem Konkurs wiedzy o Rzeszowie dla uczniów szkół
							średnich aglomeracji rzeszowskiej. Trzecią edycję Konkursu wiedzy o Rzeszowie „Rzeszów - to moje miasto, miasto, w którym
							mieszkam, uczę się i wypoczywam” Stowarzyszenie "Rzeszów to moje miasto" zorganizowało wspólnie z Instytutem Socjologii
							Uniwersytetu Rzeszowskiego, Urzędem Miasta Rzeszowa przy współpracy z Fundacją Książąt Lubomirskich oraz Towarzystwem
							Przyjaciół Rzeszowa. Natomiast czwartą edycję Konkursu Stowarzyszenie zorganizowało samodzielnie przy współpracy z Urzędem
							Miasta Rzeszowa. Konkurs dotyczy społecznej pamięci przeszłości na temat miasta, postaci i wydarzeń, które wpłynęły na jego
							dzieje, miejsc symbolicznych upamiętniających ważne osoby i organizacje, a także obejmuje współczesne przekształcenie
							przestrzeni miasta Rzeszowa.
						</p>
					</div>
				</div>
			</main>
			<section className="management--box">
				<div className="text_container">
					<h2 className="hidden">Stowarzyszenie „Rzeszów to moje miasto”</h2>
					<hr />
					<h3 className="hidden">Cel działania stowarzyszenia:</h3>
					<ul>
						<li className="hidden">
							Pielęgnowanie najlepszych tradycji wychowawczych i dydaktycznych stanowiących trwały dorobek kultury.
						</li>
						<li className="hidden">Pogłębianie i utrwalanie więzi członków stowarzyszenia.</li>
						<li className="hidden">Zachęcanie mieszkańców Rzeszowa i okolic do wspierania ośrodków kultury, edukacji i nauki.</li>
						<li className="hidden">
							Współpraca z uczelniami wyższymi, szkołami, instytucjami kultury, stowarzyszeniami, mieszkańcami oraz podmiotami
							gospodarczymi.
						</li>
					</ul>
					<h3 className="hidden">Zarząd:</h3>
					<p className="hidden">
						<b>Adam Stanisław Kulczycki</b>
						<br />
						<span>Prezes Zarządu Stowarzyszenia „Rzeszów to moje miasto”</span>
						<br />
						<b>Bogusław Plęs</b>
						<br />
						<span>
							Wiceprezes Stowarzyszenia „Rzeszów to moje miasto”
							<br />
							<b>Ryszard Lechworowicz</b>
							<br />
						</span>
						<span>
							Członek Zarządu Stowarzyszenia „Rzeszów to moje miasto”
							<br />
						</span>
						<b>Edyta Niemiec</b>
						<br />
						<span>
							Sekretarz Stowarzyszenia „Rzeszów to moje miasto”
							<br />
						</span>
						<b>Jolanta Sredniawa</b>
						<br />
						<span>
							Skarbnik Stowarzyszenia „Rzeszów to moje miasto”
							<br />
						</span>
					</p>
					<h3 className="hidden">Komisja rewizyjna:</h3>
					<p className="hidden">
						<b>Tadeusz Ochenduszko</b>
						<br />
						<span>
							Przewodniczący Komisji Rewizyjnej Stowarzyszenia „Rzeszów to moje miasto”.
							<br />
						</span>
						<b>Wojciech Pietryka</b>
						<br />
						<span>Członek Komisji Rewizyjnej Stowarzyszenia „Rzeszów to moje miasto”.</span>
					</p>
					<h3 className="hidden">Organ sprawujący nadzór:</h3>
					<p className="hidden">
						<span>Prezydent miasta Rzeszowa</span>
					</p>
					<hr />
				</div>
			</section>
		</>
	);
}
export default Association;
