import "../assets/css/NewsContent.css";

import photo10 from "../assets/image/gallery/(10).jpg";
import photo27 from "../assets/image/gallery/(27).jpg";
import photo29 from "../assets/image/gallery/(29).jpg";
import photo31 from "../assets/image/gallery/(31).jpg";
import photo41 from "../assets/image/gallery/(41).jpg";
import photo42 from "../assets/image/gallery/(42).jpg";
import photo46 from "../assets/image/gallery/(46).jpg";
import photo47 from "../assets/image/gallery/(47).jpg";
import photo50 from "../assets/image/gallery/(50).jpg";
import photo58 from "../assets/image/gallery/(58).jpg";
import photo70 from "../assets/image/gallery/(70).jpg";
import photo72 from "../assets/image/gallery/(72).jpg";
import photo74 from "../assets/image/gallery/(74).jpg";
import photo77 from "../assets/image/gallery/(77).jpg";
import photo82 from "../assets/image/gallery/(82).jpg";
import photo93 from "../assets/image/gallery/(93).jpg";
import photo101 from "../assets/image/gallery/(101).jpg";
import photo104 from "../assets/image/gallery/(104).jpg";
import photo106 from "../assets/image/gallery/(106).jpg";
import photo108 from "../assets/image/gallery/(108).jpg";
import photo110 from "../assets/image/gallery/(110).jpg";
import photo112 from "../assets/image/gallery/(112).jpg";
import photo117 from "../assets/image/gallery/(117).jpg";
import photo119 from "../assets/image/gallery/(119).jpg";
import photo122 from "../assets/image/gallery/(122).jpg";
import photo126 from "../assets/image/gallery/(126).jpg";
import photo129 from "../assets/image/gallery/(129).jpg";
import photo133 from "../assets/image/gallery/(133).jpg";
import photo137 from "../assets/image/gallery/(137).jpg";
import photo141 from "../assets/image/gallery/(141).jpg";
import photo165 from "../assets/image/gallery/(165).jpg";
import photo220 from "../assets/image/gallery/(220).jpg";

import photo10Thumbnail from "../assets/image/gallery/(10)_thumbnail.jpg";
import photo27Thumbnail from "../assets/image/gallery/(27)_thumbnail.jpg";
import photo29Thumbnail from "../assets/image/gallery/(29)_thumbnail.jpg";
import photo31Thumbnail from "../assets/image/gallery/(31)_thumbnail.jpg";
import photo41Thumbnail from "../assets/image/gallery/(41)_thumbnail.jpg";
import photo42Thumbnail from "../assets/image/gallery/(42)_thumbnail.jpg";
import photo46Thumbnail from "../assets/image/gallery/(46)_thumbnail.jpg";
import photo47Thumbnail from "../assets/image/gallery/(47)_thumbnail.jpg";
import photo50Thumbnail from "../assets/image/gallery/(50)_thumbnail.jpg";
import photo58Thumbnail from "../assets/image/gallery/(58)_thumbnail.jpg";
import photo70Thumbnail from "../assets/image/gallery/(70)_thumbnail.jpg";
import photo72Thumbnail from "../assets/image/gallery/(72)_thumbnail.jpg";
import photo74Thumbnail from "../assets/image/gallery/(74)_thumbnail.jpg";
import photo77Thumbnail from "../assets/image/gallery/(77)_thumbnail.jpg";
import photo82Thumbnail from "../assets/image/gallery/(82)_thumbnail.jpg";
import photo93Thumbnail from "../assets/image/gallery/(93)_thumbnail.jpg";
import photo101Thumbnail from "../assets/image/gallery/(101)_thumbnail.jpg";
import photo104Thumbnail from "../assets/image/gallery/(104)_thumbnail.jpg";
import photo106Thumbnail from "../assets/image/gallery/(106)_thumbnail.jpg";
import photo108Thumbnail from "../assets/image/gallery/(108)_thumbnail.jpg";
import photo110Thumbnail from "../assets/image/gallery/(110)_thumbnail.jpg";
import photo112Thumbnail from "../assets/image/gallery/(112)_thumbnail.jpg";
import photo117Thumbnail from "../assets/image/gallery/(117)_thumbnail.jpg";
import photo119Thumbnail from "../assets/image/gallery/(119)_thumbnail.jpg";
import photo122Thumbnail from "../assets/image/gallery/(122)_thumbnail.jpg";
import photo126Thumbnail from "../assets/image/gallery/(126)_thumbnail.jpg";
import photo129Thumbnail from "../assets/image/gallery/(129)_thumbnail.jpg";
import photo133Thumbnail from "../assets/image/gallery/(133)_thumbnail.jpg";
import photo137Thumbnail from "../assets/image/gallery/(137)_thumbnail.jpg";
import photo141Thumbnail from "../assets/image/gallery/(141)_thumbnail.jpg";
import photo165Thumbnail from "../assets/image/gallery/(165)_thumbnail.jpg";
import photo220Thumbnail from "../assets/image/gallery/(220)_thumbnail.jpg";

import Gallery from "../components/Gallery";

function NewsContent() {
	const images = [
		{
			original: photo10,
			thumbnail: photo10Thumbnail,
		},
		{
			original: photo27,
			thumbnail: photo27Thumbnail,
		},
		{
			original: photo29,
			thumbnail: photo29Thumbnail,
		},
		{
			original: photo31,
			thumbnail: photo31Thumbnail,
		},
		{
			original: photo41,
			thumbnail: photo41Thumbnail,
		},
		{
			original: photo42,
			thumbnail: photo42Thumbnail,
		},
		{
			original: photo46,
			thumbnail: photo46Thumbnail,
		},
		{
			original: photo47,
			thumbnail: photo47Thumbnail,
		},
		{
			original: photo50,
			thumbnail: photo50Thumbnail,
		},
		{
			original: photo58,
			thumbnail: photo58Thumbnail,
		},
		{
			original: photo70,
			thumbnail: photo70Thumbnail,
		},
		{
			original: photo72,
			thumbnail: photo72Thumbnail,
		},
		{
			original: photo74,
			thumbnail: photo74Thumbnail,
		},
		{
			original: photo77,
			thumbnail: photo77Thumbnail,
		},
		{
			original: photo82,
			thumbnail: photo82Thumbnail,
		},
		{
			original: photo93,
			thumbnail: photo93Thumbnail,
		},
		{
			original: photo101,
			thumbnail: photo101Thumbnail,
		},
		{
			original: photo104,
			thumbnail: photo104Thumbnail,
		},
		{
			original: photo106,
			thumbnail: photo106Thumbnail,
		},
		{
			original: photo108,
			thumbnail: photo108Thumbnail,
		},
		{
			original: photo110,
			thumbnail: photo110Thumbnail,
		},
		{
			original: photo112,
			thumbnail: photo112Thumbnail,
		},
		{
			original: photo117,
			thumbnail: photo117Thumbnail,
		},
		{
			original: photo119,
			thumbnail: photo119Thumbnail,
		},
		{
			original: photo122,
			thumbnail: photo122Thumbnail,
		},
		{
			original: photo126,
			thumbnail: photo126Thumbnail,
		},
		{
			original: photo129,
			thumbnail: photo129Thumbnail,
		},
		{
			original: photo133,
			thumbnail: photo133Thumbnail,
		},
		{
			original: photo137,
			thumbnail: photo137Thumbnail,
		},
		{
			original: photo141,
			thumbnail: photo141Thumbnail,
		},
		{
			original: photo165,
			thumbnail: photo165Thumbnail,
		},
		{
			original: photo220,
			thumbnail: photo220Thumbnail,
		},
	];

	return (
		<>
			<main className="news-content--box">
				<div className="text_container">
					<h1 className="hidden">Wręczono nagrody w Konkursie wiedzy o Rzeszowie</h1> <hr />
					<p className="hidden">
						<strong>
							W czwartek, 27 kwietnia, w Sali Sesyjnej Rzeszowskiego Ratusza wręczono nagrody w V edycji Konkursu wiedzy o Rzeszowie
							“Rzeszów – to moje miasto, miasto, w którym mieszkam, uczę się i wypoczywam”. W tegorocznej edycji zwyciężył Hubert Jaworski,
							uczeń IV LO w Rzeszowie.
						</strong>
					</p>
					<p className="hidden">
						Tegoroczną galę wręczenia nagród w <em>Konkursie wiedzy o Rzeszowie</em> rozpoczęto muzycznie, przypomnieniem piosenki pt.
						<strong>
							<em>„Miasto na sercu – piosenka o Rzeszowie”,</em>
						</strong>
						do której słowa napisał <strong>Janusz Koryl</strong>, a muzykę – <strong>Tomasz Filipczak</strong>, powstałej z okazji 20.
						rocznicy działalności Centrum Sztuki Wokalnej w Rzeszowie.
					</p>
					<p className="hidden">
						W uroczystej gali udział wzięli m.in.: <strong>Krystyna Stachowska</strong>, wiceprezydent miasta Rzeszowa,
						<strong>Andrzej Dec</strong>, przewodniczący Rady Miasta Rzeszowa, <strong>prof. oświaty Mirosław Kwaśniak</strong>,
						wiceprzewodniczący Rady Miasta Rzeszowa i przewodniczący Komisji Edukacji Rady Miasta Rzeszowa, a także darczyńcy i sponsorzy:
						<strong>Jerzy Graboś</strong>,prezes, dyrektor Generalny Zarządu Przedsiębiorstwa Produkcji Kruszywa i Usług Geologicznych
						KRUSZGEO w Rzeszowie, <strong>Robert Potoczny</strong>, dyrektor techniczny Miejskiego Przedsiębiorstwa Wodociągów i Kanalizacji
						w Rzeszowie, który reprezentował <strong>Marka Ustrobińskiego</strong>,prezesa MPWiK-u Sp. z o. o. oraz dyrektorzy wydziałów
						Urzędu Miasta Rzeszowa, dyrektorzy i nauczyciele szkół średnich.
					</p>
					<p className="hidden">
						–
						<em>
							Konkurs został zorganizowany po raz pierwszy w 2013 roku przez Instytut Socjologii Uniwersytetu Rzeszowskiego,
							Urząd&nbsp;Miasta Rzeszowa&nbsp;i Towarzystwo Przyjaciół&nbsp;Rzeszowa. Dotychczas odbyło się pięć jego edycji
						</em>
						– powiedziała <strong>Edyta Niemiec</strong>, sekretarz Stowarzyszenia „Rzeszów to moje miasto”. <strong>–</strong>
						<em>Tegoroczna edycja Konkursu cieszyła się bardzo dużym zainteresowaniem młodzieży</em>.
						<em>
							Zgłosiło się do niego 185 uczniów z 17 szkół ponadpodstawowych. Ażeby jego uczestnicy nie tylko musieli odwoływać się wiedzy
							książkowej, zorganizowano dla nich 10 wycieczek po Rzeszowie, których przewodnikiem był <strong>Ryszard Lechforowicz</strong>
							ze Stowarzyszenia „Rzeszów to moje miasto”. Dziękujemy Panu Ryszardowi, że zechciał pełnić tę rolę i być przewodnikiem dla
							wielu młodych ludzi po naszym Rzeszowie! Do etapu finałowego Konkursu przystąpiło ostatecznie 47 uczniów, a laureatami spośród
							nich zostało 10, zajmując pięć pierwszych miejsc.
						</em>
					</p>
					<p className="hidden">
						–
						<em>
							Kiedy w 2012 roku rozpoczynaliśmy pierwszą edycję Konkursu wiedzy o Rzeszowie
							<strong>„Rzeszów – to moje miasto, miasto, w którym mieszkam, uczę się i wypoczywam” </strong>nawet nie przypuszczałem, że
							doczekamy się jego piątej edycji
						</em>
						– stwierdził <strong>prof. nadzw. dr hab. Adam Kulczycki</strong>, prezes Stowarzyszenia „Rzeszów – to moje miasto”.
						<em>
							– Trzy pierwsze edycje Konkursu wiedzy o Rzeszowie organizowane były z inicjatywy Instytutu Socjologii Uniwersytetu
							Rzeszowskiego. Tutaj należy wspomnieć śp. już dzisiaj <strong>prof. dr hab. Mariana Malikowskiego</strong>, zasłużonego i
							wybitnego socjologa miasta i historyka, który nadawał rytm i dynamikę Konkursowi. Wspólnie z śp. już dzisiaj
							<strong>dr. hab. Janem Malczewskim,</strong> profesorem&nbsp;Uniwersytetu Rzeszowskiego, przewodniczył Komisji ekspertów i
							Komisji konkursowej.
						</em>
					</p>
					<p className="hidden">
						Podczas gali uhonorowano laureatów Konkursu wiedzy o Rzeszowie. Otrzymali oni cenne nagrody ufundowane przez Prezydenta Miasta
						Rzeszowa Konrada Fijołka, Fundację Książąt Lubomirskich, Stowarzyszenie „Rzeszów to moje miast” za sprawą darczyńców i
						sponsorów. W tegorocznej edycji zwyciężył wspomniany już <strong>Hubert Jaworski</strong>, uczeń IV LO w Rzeszowie, który został
						nagrodzony min. kopią fotografii Rzeszowa autorstwa <strong>Edwarda Janusza</strong>,jednego z najlepszych fotografów w Galicji.
						Drugie miejsce zajęli ex aequo <strong>Ida Sawicka</strong> z I LO oraz <strong>Rafał Sądek</strong> z Zespołu Szkół
						Technicznych. Miejsce trzecie zajęła <strong>Karolina Kapustyńska</strong> z Zespołu Szkół Energetycznych. Czwartą lokatę zdobył
						<strong>Alan Lubiński, </strong>uczeń I Liceum Ogólnokształcącego w Rzeszowie. A piąte miejsce piąte zajęli ex aequo:
						<strong>Jakub Dąbrowski </strong>z Zespołu Szkół Energetycznych w Rzeszowie, <strong>Adrian Dębski </strong>z Zespołu Szkół Nr 3
						im. Tadeusza Rylskiego w Rzeszowie, <strong>Maja Jarecka </strong>z I Liceum Ogólnokształcące w Rzeszowie,
						<strong>Daniel Malec </strong>z XII LO w ZSO Nr 4 w Rzeszowie, <strong>Julia Micał </strong>z III Liceum Ogólnokształcące w
						Rzeszowie.
					</p>
					<p className="hidden">
						–
						<em>
							Głównym celem Konkursu wiedzy o Rzeszowie jest odkrywanie bogactwa dziedzictwa kulturowego poprzez poznawanie przeszłości
							Rzeszowa, uświadamianie europejskiego wymiaru tego dziedzictwa i rozbudzanie oraz rozwijanie zainteresowania młodzieży szkół
							średnich swoim miastem, jego przeszłością, środowiskiem geograficznym, kulturą oraz życiem gospodarczym i społecznym
						</em>
						– powiedział prezes Stowarzyszenia „Rzeszów – to moje miasto”. –
						<em>
							To popularyzacja wiedzy na temat ludzi tworzących historię Rzeszowa, wskazywanie wzorców osobowych, kształtowanie poczucia
							więzi regionalnej poprzez upowszechnianie wiedzy o małej ojczyźnie. Konkurs przeznaczony jest dla uczniów rzeszowskich szkół
							ponadpodstawowych, a wcześniej – ponadgimnazjalnych.
						</em>
					</p>
					<p className="hidden">
						Organizatorami tegorocznej edycji konkursu byli: Stowarzyszenie „Rzeszów to moje miasto”, Prezydent Miasta Rzeszowa, Fundacja
						Książąt Lubomirskich.
					</p>
					<p className="hidden">
						Honorowy Patronat nad Konkursem wiedzy o Rzeszowie
						<strong>
							<em>„Rzeszów – to moje miasto, miasto, w którym mieszkam, uczę się i wypoczywam”</em>
						</strong>
						pełnili: <strong>Książe</strong> <strong>Jan Lubomirski-Lanckoroński</strong>, Prezes Fundacji Lubomirskich,
						<strong>Konrad Fijołek</strong>, Prezydent Miasta Rzeszowa.
					</p>
					<p className="hidden">
						–
						<em>
							Nasz konkurs nie odbyłby się bez pomocy sponsorów i darczyńców, a są nimi w tym roku: Miejskie Przedsiębiorstwo Wodociągów i
							Kanalizacji w Rzeszowie i Przedsiębiorstwo Produkcji Kruszywa i Usług Geologicznych KRUSZGEO w Rzeszowie, którym bardzo
							serdecznie dziękuję
						</em>
						– dodał prezes Stowarzyszenia.
					</p>
					<p className="hidden">
						Tegoroczną galę wręczenia nagród <em>Konkursu wiedzy o Rzeszowie</em> poprowadzili: <strong>Edyta Niemiec, </strong>sekretarz
						Stowarzyszenia „Rzeszów to moje miasto”, dyrektor Zespołu Szkół Technicznych im.
						<br />
						E. Kwiatkowskiego w Rzeszowie,<strong> Krystyna Dudzińska-Chajda,</strong> przewodnicząca Rady Osiedla Śródmieście 1 i członek
						Stowarzyszenia „Rzeszów to moje miasto”,<strong> Bożena Mazurkow, </strong>nauczyciel historii w Zespole Szkół Technicznych w
						Rzeszowie.
					</p>
					<p className="hidden">Fot. ZST w Rzeszowie</p> <hr />
				</div>
				<Gallery images={images} />
			</main>
		</>
	);
}
export default NewsContent;
